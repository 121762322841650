
import React                    from 'react';
import ReactDOM                 from 'react-dom';

const ReactHelper = {
  
  renderComponent(ComponentElement, selector) {
    const elems = document.querySelectorAll(selector);

    if (!(elems?.length > 0 && ComponentElement)) {
      return false;
    }

    elems.forEach(elem => {
      const data = JSON.parse(elem.getAttribute('data') || '{}');

      // Append 'new-component' to any existing className
      const componentClassName = ComponentElement.props.className || '';
      const existingClassName = data.className || '';
      const newClassName = `${componentClassName} ${existingClassName} new-component`.trim();
      
      const elementWithProps = React.cloneElement(ComponentElement, { ...data, className: newClassName});

      ReactDOM.render(
        elementWithProps,
        elem.appendChild(document.createElement('div')),
        () => {
          elem.classList.add('mounted');
        }
      );
    });
  },

}

export default ReactHelper;
