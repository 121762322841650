
import PropTypes                  from 'prop-types';
import React                      from 'react';

import {toClassStr}               from '_utils/UiHelper';

const PT = {
  addClasses:             PropTypes.string,
  isDisabled:             PropTypes.bool,
  onFileDragEnter:        PropTypes.func,
  onFileDrop:             PropTypes.func,
  shouldShowDragStates:   PropTypes.bool,
}


class DropWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state= {
      isDragging: false
    };
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */
  
  render = () => {
    const isDraggingClass = this.state.isDragging ? 'is-dragging' : '';
    const showDragStatesClass = this.props.shouldShowDragStates ? 'show-drag-states' : '';
    const classes = toClassStr(['drop-wrapper', isDraggingClass, showDragStatesClass, this.props.addClasses]);

    return (
      <div 
        className={classes}
        onDragOver={this.handleDragOver}
        onDragEnter={this.handleDragEnter}
        onDrop={this.handleFileDrop}
      >
        <div className="contents">
          {this.props.children}
        </div>

        <div className="drop-overlay" 
          onDragLeave={this.handleDragLeave}
        />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleDragOver = (e) => {
    if (this.props.isDisabled) {
      return false;
    }

    e.preventDefault();
    e.stopPropagation();

    if (this.state.isDragging) {
      return false;
    }

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({
        isDragging: true,
      });
    }
  };

  handleDragEnter = (e) => {
    if (this.props.isDisabled) {
      return false;
    }

    e.preventDefault();
    e.stopPropagation();

    if (this.props.onFileDragEnter) {
      this.props.onFileDragEnter(e);
    }

    return false;
  };

  handleDragLeave = (e) => {
    if (this.props.isDisabled) {
      return false;
    }
  
    e.preventDefault();
    e.stopPropagation();

    if (this.state.isDragging) {
      this.setState({
        isDragging: false,
      });
    }
  };

  handleFileDrop = (e) => {
    if (this.props.isDisabled) {
      return false;
    }

    e.preventDefault();

    this.setState({
      isDragging: false,
    });

    if (this.props.onFileDrop && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.onFileDrop(e);
    }
  };
}

DropWrapper.propTypes = PT;

export default DropWrapper;
