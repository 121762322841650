
import carouselGrid            from '../../shared/carousel-grid';
import featurePanelsSlider     from '../../shared/featurePanelsSlider';
import marketSearchBar         from '../../shared/market-search-bar';
import videoModal              from '../../shared/video-modal';
import UiHelper                from '_utils/UiHelper';


let bodyElem, publicLandingPage, testimonialsSlider, featurePanels, lazyBackgroundImages, ACTIVE_RANGE_UP_TO;

const landingPage = {

  init: function () {

    bodyElem                  = document.querySelector('body');
    publicLandingPage         = document.querySelector('.landing-page');
    testimonialsSlider        = publicLandingPage.querySelector('.band-6 .carousel-grid');
    featurePanels             = publicLandingPage.querySelector('.band-9 .feature-panels');
    lazyBackgroundImages      = publicLandingPage.querySelectorAll('.lazy-background');

    const attackWeaknessesBand = document.querySelector('.band-2');
    const navbarHeight = document.querySelector('.navbar').offsetHeight;

    ACTIVE_RANGE_UP_TO = 769;

    /*
    ==================================================
      WIRE BEHAVIORS
    ==================================================
    */


    /*
    ==================================================
      INIT CAROUSELS
    ==================================================
    */

    if (testimonialsSlider) {
      carouselGrid.init({
        breakpoint: ACTIVE_RANGE_UP_TO,
        carouselContainer: testimonialsSlider,
      });
    }

    if (featurePanels) {
      featurePanelsSlider.init({
        container: featurePanels,
      });
    }

    if (lazyBackgroundImages) {
      this.lazyLoadBackgroundImages();
    }

    /*
    ==================================================
      INIT SEARCHBAR
    ==================================================
    */

    marketSearchBar.init({
      onFocus: this.handleSearchBarFocus,
      selector: '.search-action',
      shouldSuppressInitialFocus: true,
    });
  },


  /*
  ==================================================
    EVENT HANDLERS
  ==================================================
  */

  handleCloseVideoModalRequest() {
    this.isVideoModalOpen(false);
  },

  handleOpenVideoModalRequest(videoUrl) {
    this.isVideoModalOpen(true, videoUrl);
  },

  handleSearchBarFocus() {
    let navbarHeight = document.querySelector('.navbar').offsetHeight;
    let search = document.querySelector('.band-5 .market-search-bar');

    scrollTo(search);

    function scrollTo(element){
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: (element.offsetTop - navbarHeight) - 1 // minus 1 is the amount of pixels of the border-top declaration on the search bar. Without this, the border will not show.
      });
    }
  },


  /*
  ==================================================
    LOCAL UTILS
  ==================================================
  */

  isVideoModalOpen(isOpen=false, videoUrl=null) {
    videoModal.render({
      isOpen: isOpen,
      onCloseRequest: () => this.handleCloseVideoModalRequest(),
      selector: '.modals',
      shouldAutoPlay: true,
      videoUrl: videoUrl,
    });
  },

  lazyLoadBackgroundImages() {
    let lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazy-background"));

    if ("IntersectionObserver" in window) {
      let lazyBackgroundObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            lazyBackgroundObserver.unobserve(entry.target);
          }
        });
      });

      lazyBackgrounds.forEach(function (lazyBackground) {
        lazyBackgroundObserver.observe(lazyBackground);
      });
    }
  }
};

export default landingPage;
